.custom-dropdown .dropdown-toggle.nav-link {
	padding: 0 16px;
	line-height: 34px;
	height: 34px;
	justify-content: center;
	align-items: center;
	display: flex;
}
.custom-dropdown:hover .Link-title,
.custom-dropdown:hover {
	background-color: #f7f7f7;
}

.InflDiscoverFilters .custom-dropdown {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
}
.InflDiscoverFilters .custom-dropdown > a > p {
	font-size: 13px;
}
.InflDiscoverFilters .custom-dropdown:hover .dropdown-toggle.nav-link {
	border-radius: 8px;
	border-top-left-radius: 8px;
}

.InflDiscoverFilters .custom-dropdown:first-child {
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	border-left: 1px solid #ddd;
}
.InflDiscoverFilters .custom-dropdown:last-child {
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
}
.InflDiscoverFilters #custom-selection-dropwn li,
.InflDiscoverFilters #custom-selection-dropwn label {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}
.custom-dropdown .dropdown-toggle::after {
	display: none !important;
}

.custom-dropdown .dropdown-menu {
	padding: 0 0;
	margin: 0;
	border: none;
}
.custom-dropdown.xl .dropdown-menu {
	min-width: 534px;
}
.custom-dropdown.lg .dropdown-menu {
	min-width: 300px;
}
.custom-dropdown.md .dropdown-menu {
	min-width: 250px;
}
.custom-dropdown.right .dropdown-menu {
	right: 0 !important;
}
.custom-dropdown.active {
	background-color: var(--purple);
	border-top-color: var(--purple) !important;
	border-bottom-color: var(--purple) !important;
}

.custom-dropdown.active > a {
	color: #fff !important;
}
.custom-dropdown.active > a > p {
	color: #fff !important;
	background-color: var(--purple) !important;
}
.custom-dropdown.active:hover {
	background-color: var(--purple) !important;
}
.InflDiscoverFilters .MuiAutocomplete-endAdornment {
	display: none;
}
.InflDiscoverFilters
	.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot {
	padding-right: 0px;
}

.ui.input.focus > input,
.ui.input > input:focus {
	border-color: #c4c4c4 !important;
}

/* scrollbar :start */
/* width */
.visible.menu.transition::-webkit-scrollbar {
	width: 7px;
}

/* Track */
.visible.menu.transition::-webkit-scrollbar-track {
	background: transparent;
	width: 7px;
}

/* Handle */
.visible.menu.transition::-webkit-scrollbar-thumb {
	background: #bcc0c4;
}

/* Handle on hover */
.visible.menu.transition::-webkit-scrollbar-thumb:hover {
	opacity: 0.4;
}
/* scrollbar :end */

/* custom chip :start */
.custom--chip {
	background-color: #f7f7f7;
}

.influencer-search-dropdown .visible.menu {
	width: 237px !important;
}
.influencer-search-dropdown.ui.selection.dropdown .menu > .item {
	background-color: #fff !important;
	padding: 0 !important;
}
.influencer-search-dropdown.ui.selection.dropdown
	.menu
	> .item:hover
	.ui.header {
	background-color: #f7f7f7 !important;
}
.influencer-search-dropdown .ui.header {
	padding: 0.78571429rem !important;
	box-shadow: none !important;
}
.search-total-num {
	font-family: "Poppins", sans-serif !important;
	font-size: 16px;
	letter-spacing: 0.1px;
	line-height: 24px;
	color: #222;
	font-weight: 700;
}
.vertically-centered-modal {
	z-index: 9999 !important;
	top: 100px !important;
}
.vertically-centered-modal .modal-dialog {
	margin-top: -100px !important;
}
.search-topic-tags-dropdown {
	position: absolute;
	z-index: 12;
	width: 100%;
	border-radius: 8px;
	overflow-y: auto;
	max-height: 250px;
}
.search-topic-tags-dropdown a:hover {
	opacity: 1 !important;
}
.search-topic-tags-dropdown a span {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	color: #888;
}
.search-topic-tags-dropdown a:hover span {
	color: var(--purple);
}
.topic-tags-loader {
	position: absolute;
	right: 20px;
	top: 11px;
	width: 20px !important;
	height: 20px !important;
	color: var(--purple) !important;
	z-index: 10;
}
.topic-tags-badge {
	position: absolute;
	padding: 0px 10px !important;
	top: 7px;
	font-size: 12px !important;
	line-height: 2;
	left: 10px;
	background: var(--purple) !important;
	border-color: var(--purple) !important;
	z-index: 10;
}
.topic-tags-badge span {
	background: #fff !important;
	border-radius: 34px;
	font-size: 12px;
	margin-left: 4px;
	width: 18px;
	height: 18px;
	padding: 0 !important;
	line-height: 1.5;
	position: relative;
	top: 0px;
}
.is-topic-tags input {
	padding-left: 85px !important;
}

.topic-tags-dropdown {
	display: flex;
	align-items: center;
}
