@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Fira+Sans:wght@400;500;600;700&display=swap");

@layer base {
  html,
  body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    height: 100%;
    scroll-behavior: smooth;
  }
}

/* Resoponsive sizing */

.containers {
  width: 92%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.font-firaSans {
  font-family: "Fira Sans", sans-serif;
}
/* Text shadow */

.text-shadow {
  text-shadow: 0em 0em 0.3em rgb(0 0 0 / 40%);
}

/* Scroller */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 40px;
}

.horizontal-image-slider img {
  animation: horizontal 40s linear infinite alternate;
  width: 5000px;
}

@keyframes horizontal {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translateX(100px, 0);
  }

  100% {
    transform: translate(-2000px, 0);
  }
}

.vertical-image-slide span {
  animation: nudge 10s linear infinite alternate;
}
.vertical-image-slide_2 span {
  animation: nudge 10s linear infinite alternate;
}

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translateX(0, 70%);
  }

  100% {
    transform: translate(0, -70%);
  }
}
.fade-out-up {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    white 75%,
    white 100%
  );
}
.fade-out-down {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    white 75%,
    white 100%
  );
}

button:focus-visible {
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.Input-number input[type="number"]::-webkit-inner-spin-button,
.Input-number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
  opacity: 1 !important;
}

.react-datepicker-wrapper {
  width: fit-content;
}

.reports_slider .slick-dots {
  position: unset !important;
}

/* Custom class for buttons in find influencers tab */

.gradient-filter-button{
  @apply rounded-[5px] border-[1px] border-white bg-gradient-to-t from-bgBody  to-bgBodyLight
}

.file-container {
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.75rem 1.5rem rgba(19, 19, 19, 0.03);
}

.dropzone {
  border: 0.0625rem solid #c6ccd6;
  border-radius: 0.5rem;
  background-color: #f9f9fb;
  display: block;
}
.dropzone .dropzone-container {
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8c96a8;
  z-index: 20;
}
.dropzone .dropzone-container .dropzone-title {
  padding-top: 1.5rem;
}
.dropzone .dropzone-container .browse {
  text-decoration: underline;
  color: #007bff;
}
.dropzone .file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.file-icon {
  /* Need position to allow stacking of pseudo-elements */
  position: relative;
  width: 4rem;
  height: 5.25rem;
  /* Padding for demo purposes */
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 2rem;
  color: #007bff;
  /* Second sheet of paper */
}
.file-icon, .file-icon:before, .file-icon:after {
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 0.125rem solid #b8bec9;
}
.file-icon:before, .file-icon:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.125rem solid #ccd0d8;
}
.file-icon:before {
  left: -0.625rem;
  top: 0.5rem;
  z-index: -1;
}
.file-icon:after {
  top: -0.25rem;
  right: -0.25rem;
  left: auto;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #f9f9fb #f9f9fb #b8bec9 #b8bec9;
}