.CustomLoader {
    border-radius: 4px;
    transition: opacity 400ms;
}

@keyframes pulse {
    0% {
        height: 0%;
        box-shadow: 0 0 4px rgba(0, 0, 0, 1.0);
    }

    20% {
        background: rgba(255, 255, 255, 0.5);
        height: 100%;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.0);
    }

    50% {
        background: rgba(17, 217, 255, 1);
        box-shadow: 0 0 8px rgba(17, 217, 255, 0.8);
    }

    80% {
        background: rgba(255, 255, 255, 0.5);
        height: 100%;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.0);
    }

    100% {
        height: 0%;
        box-shadow: 0 0 4px rgba(0, 0, 0, 1.0);
    }
}

.CustomLoader>ul {
    list-style: none;
    width: 100px;
    height: 100px;
}

.CustomLoader li {
    width: 4px;
    height: 30px;
    border-radius: 2px;
    background: #050505;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    transform-origin: 2px 50px;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader li div {
    border-radius: 2px;
    width: 4px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation: pulse 6s normal infinite;
}

.CustomLoader>ul>li:nth-of-type(1) {
    transform: rotate(0deg);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(1)>div {
    animation-delay: 100ms;
}

.CustomLoader>ul>li:nth-of-type(2) {
    transform: rotate(30deg);
}

.CustomLoader>ul>li:nth-of-type(2)>div {
    animation-delay: 200ms;
}


.CustomLoader>ul>li:nth-of-type(3) {
    transform: rotate(60deg);
}

.CustomLoader>ul>li:nth-of-type(3)>div {
    animation-delay: 300ms;
}


.CustomLoader>ul>li:nth-of-type(4) {
    transform: rotate(90deg);
}

.CustomLoader>ul>li:nth-of-type(4)>div {
    animation-delay: 400ms;
}


.CustomLoader>ul>li:nth-of-type(5) {
    transform: rotate(120deg);
}

.CustomLoader>ul>li:nth-of-type(5)>div {
    animation-delay: 500ms;
}


.CustomLoader>ul>li:nth-of-type(6) {
    transform: rotate(150deg);
}

.CustomLoader>ul>li:nth-of-type(6)>div {
    animation-delay: 600ms;
}


.CustomLoader>ul>li:nth-of-type(7) {
    transform: rotate(180deg);
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(7)>div {
    animation-delay: 700ms;
}


.CustomLoader>ul>li:nth-of-type(8) {
    transform: rotate(210deg);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(8)>div {
    animation-delay: 800ms;
}


.CustomLoader>ul>li:nth-of-type(9) {
    transform: rotate(240deg);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(9)>div {
    animation-delay: 900ms;
}


.CustomLoader>ul>li:nth-of-type(10) {
    transform: rotate(270deg);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(10)>div {
    animation-delay: 1000ms;
}



.CustomLoader>ul>li:nth-of-type(11) {
    transform: rotate(300deg);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(11)>div {
    animation-delay: 1100ms;
}


.CustomLoader>ul>li:nth-of-type(12) {
    transform: rotate(330deg);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

.CustomLoader>ul>li:nth-of-type(12)>div {
    animation-delay: 1200ms;
}

.CustomLoader::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 200% 240%;
    top: 0px;
    left: 0px;
    z-index: 4;
    content: ' ';
}