.topic-tags-dropdown .ui.selection.dropdown {
	height: 40px;
	min-width: 50px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	display: flex;
	align-items: center;
	border-right: 0;
}
.topic-tags-dropdown .ui.selection.dropdown i {
	padding-top: 9px !important;
	padding-right: 9px !important;
	font-size: 14px;
}
