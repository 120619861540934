.infl-slick-carousel.top-influencers .slick-prev,
.infl-slick-carousel.top-influencers .slick-next {
  top: 40%;
  width: 32px;
  height: 32px;
}
.infl-slick-carousel.top-influencers .slick-prev {
  left: 6px;
  z-index: 9;
}
.infl-slick-carousel.top-influencers .slick-next {
  right: -8px;
}
